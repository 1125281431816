import { DevicesSortFilterParamName, GetDevicesResult } from "../../services/devices.service";
import { DeviceEntry } from "./DevicesTable";
import { CellProps, Column } from "react-table";
import { FormattedMessage } from "react-intl";
import { Button, Dropdown } from "react-bootstrap";
import { Color } from "../../constants";
import { ReactComponent as ThreeDotsIcon } from "../../assets/icons/3dots.svg";
import { ReactComponent as EnableIcon } from "../../assets/icons/Enable.svg";
import { ReactComponent as DisableIcon } from "../../assets/icons/Disable.svg";
import StatusIcon from "../../icons/StatusIcon";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import { useAuthContext } from "../../context/AuthContext";
import { getIsFleetRole } from "../../util/roles.util";
import { useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import useMutationDevice from "../../hooks/useMutationDevice";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import { ReactComponent as JsonIcon } from "../../assets/icons/JSON.svg";
import { downloadJsonFile } from "../../util/shared.util";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import { Role } from "../../api/data-contracts";
import VehicleIcon from "../../icons/VehicleIcon";
import { getStatus } from "../../util/device-status.util";
import { DEVICES_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";
const NONE_OPTION = "NONE";



export function devicesToDevicesEntries(devices: GetDevicesResult) {
  return {
    pageCount: devices.pageCount,
    totalCount: devices.totalCount,
    data: devices.data?.map(
      (device): DeviceEntry => ({
        deviceId: device.id,
        deviceType: device.properties?.deviceType,
        assignmentDate: device.assignFleetDate,
        configutarionVersion: device.properties?.configFileVersion,
        licensePlate: device.installationData?.LicensePlateNumber,
        vehicleType: !device.vehicleId && !device.lastVehicleId ? undefined : device.installationData?.VehicleType,
        status: getStatus(device),
        lastTransmittionDate: device.lastEventMessage?.eventDateTime,
        fullData: device.properties,
        isActive: device.isActive,
        fleetName: device.fleetAssigned,
        vehicleId: device.vehicleId || undefined,
        lastVehicleId: device.lastVehicleId || undefined,
      })
    ),
  };
}

type OnClickBtn = (entry: DeviceEntry) => void;
export function generateColumns(
  onClickEnable?: OnClickBtn,
  onClickDisable?: OnClickBtn,
  onClickUnpair?: OnClickBtn,
  userRole?: Role
): Column<DeviceEntry>[] {
  return [
    {
      id: "checkbox",
      Cell: ({ row }: CellProps<DeviceEntry>) => (
        <CheckboxInput
          checked={row.isSelected ? "FULL" : "EMPTY"}
          onClick={() => row.toggleRowSelected(!row.isSelected)}
        />
      ),
    },
    {
      accessor: "deviceId",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="id">
          <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => <div>{value ? value : "--"}</div>,
    },
    {
      accessor: "deviceType",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="deviceType">
          <FormattedMessage id="TYPE" defaultMessage="Type" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => <div>{value ? value : "--"}</div>,
    },
    {
      accessor: "assignmentDate",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="assignFleetDate">
          <FormattedMessage id="ASSIGNMENT_DATE" defaultMessage="Assignment date" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        const { getFormattedLocalDate } = useFormattedLocalDate();
        return <div>{value ? getFormattedLocalDate(value) : "--"}</div>;
      },
    },
    {
      accessor: "configutarionVersion",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="configFileVersion">
          <FormattedMessage id="CONFIGURATION_VERSION" defaultMessage="Configuration Version" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => <div className="cut-text" style={{ maxWidth: "150px" }}>{value ? value : "--"}</div>,
    },
    {
      accessor: "licensePlate",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="vehicleId">
          <FormattedMessage id="LICENSE_PLATE" defaultMessage="License Plate" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => <div>{value ? value : "--"}</div>,
    },
    {
      accessor: "vehicleType",
      Header: <FormattedMessage id="VEHICLE_TYPE" defaultMessage="Vehicle Type" />,
      Cell: ({ value }) => (
        <div className="d-flex align-items-center gap-4">
          <VehicleIcon name={value} size="large" />
        </div>
      ),
    },
    {
      accessor: "status",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="status">
          <FormattedMessage id="STATUS" defaultMessage="Status" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) =>
        !value ? null : (
          <div className="d-flex align-items-center gap-1">
            <StatusIcon type={value} />
            {value.charAt(0) + value.slice(1).toLowerCase()}
          </div>
        ),
    },
    {
      accessor: "lastTransmittionDate",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="time">
          <FormattedMessage id="LAST_TRANSMITTION" defaultMessage="Last transmittion" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        const { getFormattedLocalDate } = useFormattedLocalDate();
        return <div>{value ? getFormattedLocalDate(value) : "--"}</div>;
      },
    },
    {
      accessor: "fullData",
      Header: <FormattedMessage id="FULL_DATA" defaultMessage="Full Data" />,
      Cell: ({ value, row }) => {
        if (!value) return null;
        return (
          <div className="d-flex align-items-center">
            <JsonIcon />
            <Button
              className="px-1"
              variant="link"
              onClick={() => downloadJsonFile(value, `v${row.original.licensePlate ?? ""}_d${row.original.deviceId}_data`)}
            >
              Reportfile
            </Button>
          </div>
        );
      },
    },
    {
      accessor: "fleetName",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="fleetAssigned">
          <FormattedMessage id="ASSIGNED_TO_FLEET" defaultMessage="Assigned to Fleet" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value, row }) => {
        const { loggedUser: { role } = {} } = useAuthContext();
        const isCipiaAdmin = role === Role.CipiaAdministrator;
        const isTsp = role === Role.SpManager || role === Role.SpUser;
        const status = row.values.status;
        if (row.isSelected && (isCipiaAdmin || isTsp)) {
          return (
            <div className="position-relative h-100 d-flex align-items-center">
              {status !== "DISABLED" ? (
                <div className="d-flex">
                  {status !== "UNPAIRED" && (userRole === Role.FleetManager || userRole === Role.FleetUser) && (
                    <ActionButton className="me-1" icon={<DisableIcon />} onClick={() => onClickUnpair?.(row.original)}>
                      <FormattedMessage id="UNPAIR" defaultMessage="Unpair" />
                    </ActionButton>
                  )}
                  <ActionButton icon={<DisableIcon />} onClick={() => onClickDisable?.(row.original)}>
                    <FormattedMessage id="DISABLE" defaultMessage="Un-Assign Forever" />
                  </ActionButton>
                </div>
              ) : (
                <ActionButton icon={<EnableIcon />} onClick={() => onClickEnable?.(row.original)}>
                  <FormattedMessage id="ENABLE" defaultMessage="Enable" />
                </ActionButton>
              )}
            </div>
          );
        } else {
          return <div>{value ? value : "--"}</div>;
        }
      },
    },
    {
      id: "ellipsis-menu",
      Cell: ({ row: { original } }: CellProps<DeviceEntry>) => {
        const isFleetRole = getIsFleetRole(useAuthContext().loggedUser?.role);
        const { activeTsp } = useTspFleetContext();
        const { assignToFleet, isLoadingAssignToFleet, unassignFleet, isLoadingUnassignFleet } = useMutationDevice();
        const isLoading = isLoadingAssignToFleet || isLoadingUnassignFleet;

        if (isFleetRole) return null;
        const isAnyFleetAssigned = !!original.fleetName;
        return (
          <Dropdown
            onSelect={(fleetIdOrNone) =>
              fleetIdOrNone === NONE_OPTION
                ? unassignFleet(original.deviceId!)
                : assignToFleet({ deviceId: original.deviceId!, fleetId: Number.parseInt(fleetIdOrNone!) })
            }
          >
            <Dropdown.Toggle className="cursor-pointer" as={ThreeDotsIcon} />
            <Dropdown.Menu>
              <Dropdown.Header>
                <FormattedMessage id="DEVICE_ASSIGNMENT" defaultMessage="Device Assignment" />
              </Dropdown.Header>
              <Dropdown.Item
                key={NONE_OPTION}
                eventKey={NONE_OPTION}
                disabled={!isAnyFleetAssigned || isLoading}
                style={!isAnyFleetAssigned ? { backgroundColor: Color.LIGHT_GRAY_5 } : undefined}
              >
                <FormattedMessage id="NONE" defaultMessage="None" />
              </Dropdown.Item>
              {activeTsp?.children.map((fleet) => {
                const isCurrent = fleet.companyFriendlyName === original.fleetName;
                return (
                  <Dropdown.Item
                    key={fleet.id}
                    eventKey={fleet.id}
                    disabled={isLoading}
                    style={isCurrent ? { backgroundColor: Color.LIGHT_GRAY_5 } : undefined}
                  >
                    {fleet.companyFriendlyName}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
}
