import styles from "./DriversTableController.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import addIconPath from "../../assets/icons/Add.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Close.svg";
import { Color, InputUi } from "../../constants";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import ExpandingTreeFilter from "../Filters/ExpandingTreeFilter";
import { DriversSortFilterParamName } from "../../services/driver.service";
import { getEventMetadata, getEventsCheckboxTree } from "../../util/events.util";
import TextInputFilter from "../Filters/TextInputFilter";
import DateTimeSelectFilter from "../Filters/DateTimeSelectFilter";
import { DRIVERS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

interface DriversControllerProps {
  onClickAdd: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  driversCount?: number;
  disable?: {
    add?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
}
const eventOptionsTree = getEventsCheckboxTree();
function DriversTableController({
  onClickAdd,
  onClickEdit,
  onClickDelete,
  driversCount,
  disable,
}: DriversControllerProps) {
  const { formatMessage } = useIntl();
  const allString = formatMessage({
    id: "ALL",
    defaultMessage: "All",
  });
  return (
    <div className="d-flex">
      <div className="d-flex align-self-center gap-2">
        <div className="header-font me-5">
          <FormattedMessage id="DRIVERS" defaultMessage="Drivers" />
          {` (${driversCount || 0})`}
        </div>
        <ActionButton icon={<EditIcon fill={Color.CIPIA_BLUE} />} onClick={onClickEdit} disabled={disable?.edit}>
          <FormattedMessage id="DRIVER_CARD" defaultMessage="Driver Card" />
        </ActionButton>

        <ActionButton
          icon={<img src={addIconPath} className={styles.btn_img} alt="add" />}
          onClick={onClickAdd}
          disabled={disable?.add}
        >
          <FormattedMessage id="CREATE" defaultMessage="Create" />
        </ActionButton>

        <ActionButton icon={<DeleteIcon fill={Color.CIPIA_BLUE} />} onClick={onClickDelete} disabled={disable?.delete}>
          <FormattedMessage id="DELETE" defaultMessage="Delete" />
        </ActionButton>
      </div>
      <div className="d-flex align-self-center ms-auto gap-2 py-1">
        <ExpandingTreeFilter<DriversSortFilterParamName>
          context={DRIVERS_CONTEXT}
          nodes={eventOptionsTree}
          operator="=="
          paramName="eventMessageType"
          placeHolder={allString}
          inputLabel={formatMessage({
            id: "EVENT_TYPE",
            defaultMessage: "Event Type",
          })}
          width={InputUi.SINGLE_STD_WIDTH}
          getLabel={(value) => getEventMetadata(value)?.displayName || value}
          includeInUrl
        />
        <TextInputFilter<DriversSortFilterParamName>
          context={DRIVERS_CONTEXT}
          paramName="driverId"
          operator="@=*"
          placeHolder={allString}
          inputLabel={formatMessage({
            id: "DRIVER_ID",
            defaultMessage: "Driver ID",
          })}
          style={{ width: InputUi.SINGLE_STD_WIDTH }}
          includeInUrl
        />
        <TextInputFilter<DriversSortFilterParamName>
          context={DRIVERS_CONTEXT}
          paramName="name"
          operator="@=*"
          placeHolder={allString}
          inputLabel={formatMessage({
            id: "NAME",
            defaultMessage: "Name",
          })}
          style={{ width: InputUi.SINGLE_STD_WIDTH }}
          includeInUrl
        />
        <DateTimeSelectFilter
          context={DRIVERS_CONTEXT}
          paramName="lastEventTime"
          width={InputUi.SINGLE_STD_WIDTH}
          includeInUrl
          urlFromParamName="from"
          urlToParamName="to"
        />
      </div>
    </div>
  );
}

export default DriversTableController;
