import { FleetEntry } from "../../FleetView/FleetView";
import useQueryTrips from "../../../hooks/useQueryTrips";
import { toTripItem, tripsQueryResultToTableData } from "../trips-utils";
import DateTimeSelectFilter from "../../Filters/DateTimeSelectFilter";
import { TRIPS_CONTEXT } from "../../../context/QueryParamsContext/queryParamContextObjects";
import { InputUi } from "../../../constants";
import TripsList from "../TripsList/TripsList";
import Pagination from "../../Table/Pagination";
import { useTripsQueryParamsContext } from "../../../context/QueryParamsContext/TripsQueryParamsContextProvider";
import { Spinner } from "react-bootstrap";

type VehicleTripsProps = {
  selectedDataIndex?: number;
  onClickOnViewTrip: (tripId: number) => void;
  onDataChange?: (data: FleetEntry[]) => void;
};

export default function VehicleTrips({ selectedDataIndex, onClickOnViewTrip, onDataChange }: VehicleTripsProps) {
  const { queryParams, setPageIndex } = useTripsQueryParamsContext();

  const { data: trips, isLoading, isError } = useQueryTrips(tripsQueryResultToTableData);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <DateTimeSelectFilter context={TRIPS_CONTEXT} paramName="firsteventtime" width={InputUi.SINGLE_STD_WIDTH} />
      </div>
      {trips && (
        <TripsList
          onClickOnTripView={onClickOnViewTrip}
          items={toTripItem(trips?.data)}
        />)
      }
      {isError ? (
        <div>Error With Receiving Data</div>
      ) : (
        isLoading && (
          <div>
            <Spinner as="span" animation="border" size="sm" className="m-auto" />
            Loading...
          </div>
        )
      )}

      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={trips?.pageCount}
        allDisabled={isLoading}
        totalEntries={trips?.totalCount}
      />
    </>
  );
}
