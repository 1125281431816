import { Trip } from "../../api/data-contracts";
import { GetEventsParams } from "../../services/events.service";
import { GetTripsParams, GetTripsResult } from "../../services/trips.service";
import { TripItem } from "./TripsList/TripsList";

export function getInitialTripsQueryParams(tripId?: number, deviceId?: string): GetTripsParams {
  return {
    filters: [
      { paramName: "tripId", operator: "==", values: [tripId?.toString() ?? ""] },
      { paramName: "deviceId", operator: "==", values: [deviceId?.toString() ?? ""] },
    ],
    sorts: [],
    paging: { pageIndex: 1, pageSize: 10 },
  };
}
export function toTripItem(data: Trip[] | undefined): TripItem[] | undefined {
  return data?.map((trip) => {
    return {
      tripId: trip.tripID,
      date: trip?.firstEvent?.time,
      driverName: trip?.drivers && trip?.drivers[0]?.name,
      drivers: trip.drivers,
      from: trip?.firstEvent?.address,
      to: trip?.lastEvent?.address,
      totalSafetyEvents: trip?.totalSafetyEvents,
      vehicleId: trip?.vehicleId,
    };
  });
}
export function tripsQueryResultToTableData(queryResult: GetTripsResult) {
  return {
    pageCount: queryResult?.pageCount,
    totalCount: queryResult.totalCount,
    data: queryResult?.data || [],
  };
}
export function getInitialTripsEventQueryParams(tripId?: number, deviceId?: string): GetEventsParams {
  return {
    filters: [
      { paramName: "tripId", operator: "==", values: [tripId?.toString() ?? ""] },
      { paramName: "deviceID", operator: "==", values: [deviceId ?? ""] },

    ],
    sorts: [],
    paging: { pageIndex: 1, pageSize: 10 },
  };
}

export function getInitialDriverTripsQueryParams(driverId: string): GetTripsParams {
  return {
    filters: [{ paramName: "driverId", operator: "==", values: [driverId] }],
    sorts: [],
    paging: { pageIndex: 1, pageSize: 10 },
  };
}
export function getInitialVehicleTripsQueryParams(vehicleId: string): GetTripsParams {
  return {
    filters:  [{ paramName: "vehicleId", operator: "==", values: [vehicleId] }],
    sorts: [],
    paging: { pageIndex: 1, pageSize: 10 },
  };
}
