import { Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import EventIcon from "../../../icons/EventIcon";

import { useEffect } from "react";
import { CellProps, Column, Row } from "react-table";
import { useFormattedLocalDate } from "../../../hooks/useFormattedLocalDate";
import useQueryEvents from "../../../hooks/useQueryEvents";
import { getEventMetadata } from "../../../util/events.util";
import { FleetEntry } from "../../FleetView/FleetView";
import { eventsQueryResultToTableData } from "../../FleetView/fleet-utils";
import Table from "../../Table/Table";
import styles from "./CurrentTrip.module.scss";
import SortingHeaderContainer from "../../SortingHeaderContainer/SortingHeaderContainer";
import { EventsSortAndFilterParamNames } from "../../../services/events.service";
import useQueryTrips from "../../../hooks/useQueryTrips";
import { Color } from "../../../constants";
import { ReactComponent as TripsIcon } from "../../../assets/icons/Trip.svg";
import { ReactComponent as SafetyEventsIcon } from "../../../assets/icons/SafetyEvents.svg";
import { Button } from "../../Button/Button";
import { EVENTS_CONTEXT } from "../../../context/QueryParamsContext/queryParamContextObjects";
import { tripsQueryResultToTableData } from "../trips-utils";

type TripEventsTableEntry = {
  dateTime?: string;
  type?: string;
  locationAddr?: string;
  messageId?: number;
};

const columns: Column<TripEventsTableEntry>[] = [
  {
    accessor: "dateTime",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="eventDateTime">
        <FormattedMessage id="TIME" defaultMessage="Time" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }) => {
      const { getFormattedLocalDate } = useFormattedLocalDate();
      return !value ? null : <div>{getFormattedLocalDate(value)}</div>;
    },
  },
  {
    accessor: "type",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="eventType">
        <FormattedMessage id="EVENT" defaultMessage="Event" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value, row: { original } }: CellProps<TripEventsTableEntry>) => (
      <div className="d-flex align-items-center gap-2">
        <EventIcon serverName={value} />
        <div>{getEventMetadata(value)?.displayName}</div>
      </div>
    ),
  },
  {
    accessor: "locationAddr",
    Header: <FormattedMessage id="LOCATION" defaultMessage="Location" />,
  },
];

type CurrentTripProps = {
  tripId: number;
  onDataChange?: (data: FleetEntry[]) => void;
  onSetDriverTrips: (driverId: string) => void;
  selectedDataIndex?: number;
  onSelectDataIndex?: (index: number) => void;
};

export default function CurrentTrip({
  tripId,
  selectedDataIndex,
  onDataChange,
  onSetDriverTrips,
  onSelectDataIndex,
}: CurrentTripProps) {
  const { data: events, isLoading, isError } = useQueryEvents(eventsQueryResultToTableData,true,true);


  const { data: trips } = useQueryTrips(tripsQueryResultToTableData);

  useEffect(() => {
    if (!events) return;
    onDataChange?.(events?.data);
  }, [onDataChange, events]);

  function onEventClick(row: Row<TripEventsTableEntry>) {
    onSelectDataIndex?.(row.index);
  }
  const viewEvents = events?.data?.filter(evt=> evt.lastEvent?.category === 0).map<TripEventsTableEntry>((item) => ({
    messageId: item.lastEvent?.id,
    locationAddr: item.lastEvent?.location?.address ?? "",
    type: item.lastEvent?.type ?? "",
    dateTime: item.lastEvent?.location.time,
  }));

  return (
    <Stack gap={3} className="overflow-hidden">
      <Stack gap={1} className="flex-grow-0">
        <Stack direction="horizontal" gap={2}>
          <div className="d-flex" style={{ width: "25%" }}>
            <FormattedMessage id="CURRENT_TRIP" defaultMessage="Current trip" />:
          </div>
          <span>{tripId || "--"}</span>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <div className="d-flex" style={{ width: "25%" }}>
            <FormattedMessage id="DRIVER" defaultMessage="Driver" />:
          </div>
          {trips?.data[0]?.drivers?.map((d) => {
            return (
              <Button
                onClick={() => onSetDriverTrips(d.driverId || "")}
                className="bg-transparent border-0 text-dark p-2"
                key={`driver-${d.driverId}-{index}`}
              >
                <TripsIcon />
                <span style={{ color: Color.CIPIA_BLUE, marginLeft: "0.25rem", textDecoration: "underline" }}>
                  {d.name}
                </span>
              </Button>
            );
          })}
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <SafetyEventsIcon />
          <FormattedMessage id="SAFETY_EVENTS" defaultMessage="Safety Events" />:<span>{viewEvents?.length}</span>
        </Stack>
      </Stack>
      <Table
        // hideHeader
        data={viewEvents || []}
        columns={columns}
        classes={{
          wrapper: () => styles["table-wrapper"],
          table: () => styles.table,
          thead: () => styles.thead,
          th: () => styles.th,
          tr: (row) => {
            return `${row.index === selectedDataIndex ? "selected-row-bg" : ""} cursor-pointer`;
          },
          td: () => styles.td,
        }}
        onClickRow={onEventClick}
        isLoading={isLoading}
        isError={isError}
      />
    </Stack>
  );
}
